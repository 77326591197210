import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Newsletter from "../common/newsletter";
import Social from "../common/social";
import Gutter from "../common/gutter";
import Brand from "../common/brand";

const Footer = styled.footer`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: end;
`;

const FooterSection = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
`;

const Contact = ({ socials }) => (
  <Footer>
    <Brand
      variant="slogan"
      css={`
        margin-top: 40px;
      `}
    />
    <FooterSection>
      <Newsletter />
      <Gutter />
      <Social socials={socials} />
    </FooterSection>
  </Footer>
);

Contact.propTypes = {
  socials: Social.propTypes.socials,
  socialTitle: PropTypes.string,
};

export default Contact;
