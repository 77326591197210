import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";
import { useBoundingRect } from "../util/use-width";
import { ReactComponent as ChevronLeft } from "../../svgs/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../svgs/chevron-right.svg";

import ContentUnit from "./content-unit";

const Root = styled.div`
  position: relative;
  width: 100%;
  left: 0;
  height: ${({ height }) => height}px;
`;

const StyledContent = styled(ContentUnit).attrs(({ offset }) => ({
  style: { left: offset },
}))`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  position: absolute !important;
  object-fit: cover;
  transition: left 1s, opacity 0.3s;
  -webkit-tap-highlight-color: transparent;
  user-select: ${({ active }) => (active ? "unset" : "none")};
  cursor: ${({ active }) => (active ? "unset" : "pointer")};
  opacity: ${({ active }) => (active ? 1 : 0.6)};
`;

const Chevron = styled.svg`
  top: ${({ height }) => height / 2}px;
  ${({ right }) => `${right ? "right" : "left"}: 10px`};
  background-color: #fff4;
  z-index: 100;
  color: black;
  transform: translateY(-50%);
  display: block;
  position: absolute;
  cursor: pointer;
  padding: 22px;
  border-radius: 100%;
  user-select: none;
  height: 70px;
  width: 70px;
  ${({ theme }) => theme.media.mobile} {
    height: 60px;
    width: 60px;
  }
  &:focus {
    outline: none;
  }
`;

function modularIndex(array, index) {
  let modularized = index % array.length;
  if (modularized < 0) modularized += array.length;
  return modularized;
}

const Caroussel = ({ slides, spacing = 32, height, aspectRatio }) => {
  const [centerIndex, setCenterIndex] = useState(0);
  const [playing, setPlaying] = useState(true);

  const ref = useRef();
  const { left, right } = useBoundingRect(ref) || { left: 0, right: 1000 };
  let containerWidth = right - left;

  const innerImageWidth = aspectRatio * height;
  const outerImageWidth = innerImageWidth + spacing;

  const globalOffset = containerWidth / 2 - innerImageWidth / 2;
  const sideImages = Math.ceil((containerWidth / outerImageWidth - 1) / 2) * 2;
  const totalImages = sideImages * 2 + 1;

  const swipeable = useSwipeable({
    onSwipedRight: () => setCenterIndex(centerIndex - 1),
    onSwipedLeft: () => setCenterIndex(centerIndex + 1),
  });

  return (
    <Root
      height={height}
      {...swipeable}
      ref={(element) => {
        ref.current = element;
        swipeable.ref(element);
      }}
    >
      <Chevron
        as={ChevronLeft}
        height={height}
        onClick={() => setCenterIndex(centerIndex - 1)}
      />
      <Chevron
        as={ChevronRight}
        height={height}
        right
        onClick={() => setCenterIndex(centerIndex + 1)}
      />
      {new Array(totalImages).fill().map((_, i) => {
        const position = i - sideImages;
        const imageIndex = centerIndex + position;
        const slide = slides[modularIndex(slides, imageIndex)];
        const imageOffset = globalOffset + position * outerImageWidth;
        return (
          <StyledContent
            key={`${imageIndex}+${sideImages}`}
            {...slide}
            active={centerIndex === imageIndex}
            playing={centerIndex === imageIndex && playing}
            height={height}
            width={innerImageWidth}
            offset={imageOffset}
            onClick={() => {
              setCenterIndex(imageIndex);
            }}
            onPlayingChange={(newPlaying) =>
              setPlaying(centerIndex === imageIndex && newPlaying)
            }
          />
        );
      })}
    </Root>
  );
};

Caroussel.propTypes = {};

export default Caroussel;
