import React from "react";
import styled from "styled-components";
import Image from "gatsby-image";

import InlineVideo from "./inline-video";

const Root = styled.div`
  & *:last-child {
    margin-bottom: 0;
  }
  & p {
    margin: 0;
    margin-bottom: 14px;
    line-height: 20px;
  }
  & h5 {
    font-family: "Big Caslon";
    font-size: 28px;
    margin: 0;
    padding: 0;
    font-weight: normal;
    margin-bottom: 28px;
  }
  & video,
  & img {
    object-fit: cover !important;
    object-position: ${({ lateralAnchor, verticalAnchor }) =>
      `${lateralAnchor} ${verticalAnchor}`} !important;
  }
`;

const TextContent = styled.div`
  width: 100%;
  height: 100%;
  font-family: "Libre Baskerville";
  font-size: 14px;
  display: flex;
  flex-direction: column;
  text-align: ${({ lateralAnchor }) => lateralAnchor};
  justify-content: ${({ verticalAnchor }) => {
    switch (verticalAnchor) {
      case "top":
        return "flex-start";
      case "center":
        return "center";
      case "bottom":
        return "flex-end";
      default:
        return "flex-end";
    }
  }};
  vertical-align: bottom;
`;

const ContentUnit = ({
  type,
  html,
  image,
  videoURL,
  playing,
  lateralAnchor,
  verticalAnchor,
  onPlayingChange,
  className,
  style,
  onClick,
  showVolume,
}) => {
  const rootProps = {
    className,
    lateralAnchor,
    verticalAnchor,
    style,
    onClick,
  };
  switch (type) {
    case "image":
      return (
        <Root {...rootProps}>
          <Image
            {...image}
            css={`
              width: 100%;
              height: 100%;
            `}
            placeholderStyle={{ filter: "blur(14px)" }}
            loading="eager"
          />
        </Root>
      );
    case "video":
      return (
        <Root {...rootProps}>
          <InlineVideo
            src={videoURL}
            playing={playing}
            poster={(image?.fixed || image?.fluid)?.src}
            showVolume={showVolume}
            onPlayingChange={onPlayingChange}
          />
        </Root>
      );
    case "text":
    default:
      return (
        <Root {...rootProps}>
          <TextContent dangerouslySetInnerHTML={{ __html: html }} />
        </Root>
      );
  }
};

ContentUnit.propTypes = {};

export default ContentUnit;
