import { useLayoutEffect, useState } from "react";

let PolyfilledResizeObserver;
(async () => {
  if (typeof window !== "undefined" && window.ResizeObserver) {
    PolyfilledResizeObserver = window.ResizeObserver;
  } else {
    PolyfilledResizeObserver = (await import("resize-observer-polyfill"))
      .default;
  }
})();

export function useBoundingRect(ref) {
  const [boundingRect, setBoundingRect] = useState();
  useLayoutEffect(() => {
    setBoundingRect(ref.current.getBoundingClientRect());
    let awaitingAnimation = false;
    const useBoundingRectResizeListener = () => {
      if (!awaitingAnimation) {
        awaitingAnimation = true;
        requestAnimationFrame(() => {
          setBoundingRect(ref.current.getBoundingClientRect());
          awaitingAnimation = false;
        });
      }
    };
    const observer = new PolyfilledResizeObserver(
      useBoundingRectResizeListener
    );
    observer.observe(ref.current.parentElement);
    return () => observer.disconnect();
  }, [ref]);
  return boundingRect;
}
