import React, { useEffect, useState } from "react";
import styled from "styled-components";

const ArrowButton = styled.button`
  height: 80px;
  width: 80px;
  display: inline-block;
  transform-origin: center;
  transform: ${({ show }) => (show ? "scale(1)" : "scale(0)")};
  transition: transform 0.2s;
  border: none;
  text-decoration: none;
  background: transparent;
  color: black;
  cursor: pointer;
  text-align: center;
  padding: 0;
  margin: 0;
  border-radius: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.07);
    outline: none;
  }

  padding-top: 5px;
  position: fixed;
  bottom: 30px;
  right: 30px;
`;

const BackToTop = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let lastY;
    let enqueued = false;
    const listener = () => {
      if (!enqueued) {
        enqueued = true;
        requestAnimationFrame(() => {
          setShow(window.scrollY < lastY && window.scrollY !== 0);
          lastY = window.scrollY;
          enqueued = false;
        });
      }
    };
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, []);

  return (
    <ArrowButton
      show={show}
      onClick={({ target }) => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        target.blur();
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="60%"
        viewBox="0 0 9.875 15.963"
      >
        <defs>
          <clipPath clipPathUnits="userSpaceOnUse" id="b">
            <path d="M1832.108 101.825l-13.264-13.57a.76.76 0 01-.215-.535c0-.199.078-.391.216-.532a.724.724 0 011.042 0l12.742 13.038 12.743-13.038a.724.724 0 011.023 0 .766.766 0 01.018 1.067l-13.262 13.57a.724.724 0 01-1.043 0" />
          </clipPath>
          <clipPath clipPathUnits="userSpaceOnUse" id="a">
            <path d="M0 2369.587h1920V0H0z" />
          </clipPath>
        </defs>
        <g
          clip-path="url(#a)"
          transform="matrix(.35278 0 0 -.35278 -641.572 36)"
        >
          <path
            d="M1832.629 100.226v-42.43"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-miterlimit="10"
          />
        </g>
        <g
          clip-path="url(#b)"
          transform="matrix(.35278 0 0 -.35278 -641.572 36)"
        >
          <path d="M-2503.878-1317.545h6074v1440h-6074z" fill="currentColor" />
        </g>
      </svg>
    </ArrowButton>
  );
};

BackToTop.propTypes = {};

export default BackToTop;
