import React from "react";
import styled from "styled-components";
import Text from "../common/text";

import Image from "gatsby-image";
import { Link } from "gatsby";

const Root = styled(Link)`
  display: flex;
  width: max-content;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  text-align: center;
`;

const NextCase = ({ title, image, subtitle, url }) => {
  return (
    <Root to={url}>
      <Text font="subtitle">next</Text>
      <Text
        font="subtitle"
        css={`
          font-size: 30px;
        `}
      >
        {title}
      </Text>
      <Image
        {...image}
        css={`
          height: 200px;
          width: 350px;
          margin: 20px 0;
        `}
        placeholderStyle={{ filter: "blur(14px)" }}
        loading="eager"
      />
      <Text font="subtitle">{subtitle}</Text>
    </Root>
  );
};

NextCase.propTypes = {};

export default NextCase;
