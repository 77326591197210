import React, { useRef, useState } from "react";
import styled from "styled-components";

import Image from "gatsby-image";

import InlineVideo from "./inline-video";

const StyledMedia = styled(InlineVideo)`
  width: 100%;
  max-height: 90vh;
  cursor: pointer;
`;

const FullsizeMedia = ({ type, media }) => {
  const ref = useRef();
  const [playing, setPlaying] = useState(true);

  return (
    <StyledMedia
      as={type === "video" ? InlineVideo : Image}
      {...media}
      ref={ref}
      muted
      loop
      playing={playing}
      onPlayingChange={(newPlaying) => setPlaying(newPlaying)}
      placeholderStyle={{ filter: "blur(14px)" }}
      loading="eager"
      poster={(media.fluid || media.fixed)?.src}
    />
  );
};

FullsizeMedia.propTypes = {};

export default FullsizeMedia;
