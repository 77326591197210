import React from "react";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ rootAnchor = "center" }) => rootAnchor};
  margin-bottom: ${({ spacing = 8 }) => -spacing}px;
`;

const Item = styled.div`
  margin-bottom: ${({ spacing = 8 }) => spacing}px;
  margin-left: ${({ marginLeft = 0 }) => marginLeft}px;
  margin-right: ${({ marginRight = 0 }) => marginRight}px;
  width: ${({ width = 'auto' }) => width}px;
  text-align: ${({ lateralAnchor = "left" }) => lateralAnchor};
  align-self: ${({ verticalAnchor = "bottom" }) => {
    switch (verticalAnchor) {
      case "top":
        return "flex-start";
      case "center":
        return "center";
      case "bottom":
        return "flex-end";
      default:
        return "flex-end";
    }
  }};
  & p {
    font-family: "Libre Baskerville";
    margin: 0;
    margin-bottom: 14px;
    line-height: 20px;
    font-size: 14px;
  }
  & h5 {
    font-family: "Big Caslon";
    font-size: 28px;
    margin: 0;
    padding: 0;
    font-weight: normal;
    margin-bottom: 28px;
  }
  & *:last-child {
    margin-bottom: 0;
  }
`;

function mapRootAnchor(anchor) {
  if (anchor === "left") return "flex-start";
  if (anchor === "right") return "flex-end";
  return anchor;
}

const Text = ({ rootAnchor = "center", spacing, contents }) => (
  <Root spacing={spacing} rootAnchor={mapRootAnchor(rootAnchor)}>
    {contents.map(({ content, ...rest }) => (
      <Item
        spacing={spacing}
        {...rest}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    ))}
  </Root>
);

Text.propTypes = {};

export default Text;
