import { graphql } from "gatsby";
import React from "react";
import { withPreview } from "gatsby-source-prismic";

import CaseComponent from "../components/case";
import SEO from "../components/common/seo";

const CaseTemplate = ({ data }) => {
  const socials =
    data.prismicSocialNetworks?.data.social_networks.map((social) => ({
      name: social.icon.alt || "",
      iconURL: social.icon.fixed.src,
      linkURL: social.url.url,
      iconSize: social.scale || 1,
    })) ?? [];

  const caseData = data.prismicCase.data;
  const textSection = {
    supertitle: caseData.supertitle,
    title: caseData.title,
    subtitle: caseData.subtitle,
    children: caseData.description.html,
  };
  const textSectionMarginBottom = caseData.margin_bottom;

  const visualSections = caseData.body.map(({ slice_type: type, ...slice }) => {
    switch (type) {
      case "fullsize":
        if (typeof slice.primary.video_url.url === "string") {
          return {
            type,
            marginBottom: slice.primary.margin_bottom,
            constrainWidth: slice.primary.constrain_width,
            section: {
              type: "video",
              media: {
                ...slice.primary.image,
                src: slice.primary.video_url.url,
                showVolume: slice.primary.show_volume,
              },
            },
          };
        } else {
          return {
            type,
            marginBottom: slice.primary.margin_bottom,
            constrainWidth: slice.primary.constrain_width,
            section: { type: "image", media: slice.primary.image },
          };
        }
      case "caroussel":
      case "gallery":
        return {
          type,
          marginBottom: slice.primary.margin_bottom,
          constrainWidth: slice.primary.constrain_width,
          section: {
            aspectRatio: slice.primary.aspect_ratio,
            height: slice.primary.height,
            spacing: slice.primary.spacing,
            slides: slice.items.map((item) => {
              const anchors = {
                lateralAnchor: item.lateral_anchor,
                verticalAnchor: item.vertical_anchor,
              };
              if (item.url_video.url) {
                return {
                  type: "video",
                  ...anchors,
                  videoURL: item.url_video.url,
                  image: item.image,
                  showVolume: item.show_volume,
                };
              } else if (item.image.fluid) {
                return {
                  type: "image",
                  ...anchors,
                  image: item.image,
                };
              } else
                return {
                  type: "text",
                  ...anchors,
                  html: item.text.html,
                };
            }),
          },
        };
      case "text":
        return {
          type,
          marginBottom: slice.primary.margin_bottom,
          section: {
            rootAnchor: slice.primary.root_anchor,
            spacing: slice.primary.spacing,
            contents: slice.items.map((item) => ({
              content: item.content.html,
              width: item.width,
              lateralAnchor: item.lateral_anchor,
              verticalAnchor: item.vertical_anchor,
              marginLeft: item.margin_left,
              marginRight: item.margin_right,
            })),
          },
        };
      default:
        return null;
    }
  });

  const nextCase = {
    title: caseData.next_case_title,
    subtitle: caseData.next_case_subtitle,
    url: caseData.next_case_link.url,
    image: caseData.next_case_thumbnail,
  };

  return (
    <>
      <SEO
        title={caseData.page_title.text}
        description={caseData.description.text}
      />
      <CaseComponent
        textSection={textSection}
        textSectionMarginBottom={textSectionMarginBottom}
        visualSections={visualSections}
        socials={socials}
        nextCase={nextCase}
      />
    </>
  );
};

export default withPreview(CaseTemplate);

export const query = graphql`
  query CaseQuery($id: String = "") {
    prismicCase(id: { eq: $id }) {
      id
      data {
        title
        supertitle
        subtitle
        page_title {
          text
        }
        next_case_title
        next_case_subtitle
        next_case_link {
          url
        }
        next_case_thumbnail {
          fixed(width: 350, height: 200) {
            ...GatsbyPrismicImageFixed
          }
        }
        description {
          html
          text
        }
        margin_bottom
        body {
          ... on PrismicCaseBodyFullsize {
            id
            slice_type
            primary {
              margin_bottom
              content_title
              image {
                fluid(maxHeight: 1080, maxWidth: 1920) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              video_url {
                url
              }
              show_volume
            }
          }
          ... on PrismicCaseBodyCaroussel {
            id
            slice_type
            primary {
              margin_bottom
              spacing
              height
              aspect_ratio
            }
            items {
              lateral_anchor
              vertical_anchor
              image {
                fluid(maxHeight: 1000, maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              text {
                html
              }
              url_video {
                url
              }
              show_volume
            }
          }
          ... on PrismicCaseBodyGallery {
            id
            slice_type
            primary {
              margin_bottom
              constrain_width
              spacing
              height
              aspect_ratio
            }
            items {
              lateral_anchor
              vertical_anchor
              image {
                fluid(maxHeight: 1000, maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              text {
                html
              }
              url_video {
                url
              }
              show_volume
            }
          }
          ... on PrismicCaseBodyText {
            id
            items {
              content {
                html
              }
              lateral_anchor
              margin_left
              margin_right
              vertical_anchor
              width
            }
            slice_type
            primary {
              root_anchor
              spacing
              margin_bottom
            }
          }
        }
      }
    }
    prismicSocialNetworks {
      data {
        social_networks {
          icon {
            fixed {
              src
            }
          }
          scale
          url {
            url
          }
        }
      }
    }
  }
`;
