import React from "react";
import styled, { css } from "styled-components";

import Body from "../common/body";
import Header from "../common/header";
import Gutter from "../common/gutter";
import TextSection from "../common/text-section";

import Footer from "./footer";
import NextCase from "./next-case";
import FullsizeMedia from "./fullsize-media";
import Caroussel from "./caroussel";
import Gallery from "./gallery";
import Text from "./text";
import BackToTop from "./back-to-top";

const componentMap = {
  fullsize: FullsizeMedia,
  caroussel: Caroussel,
  gallery: Gallery,
  text: Text,
};

const MarginContainer = styled.div`
  ${({ constrainWidth }) =>
    constrainWidth &&
    css`
      max-width: 980px;
      margin: 0 auto;
      ${({ theme }) => theme.media.mobile} {
        ${({ theme }) => theme.spacing.padding(0, 4)};
      }
      position: relative;
      overflow: hidden;
    `}
`;

const CaseIndex = ({
  textSection,
  textSectionMarginBottom,
  visualSections,
  nextCase,
  socials,
}) => (
  <>
    <Body
      css={`
        margin-bottom: 0;
        padding-bottom: 0;
      `}
    >
      <Header />
      <Gutter size={80} />
      <TextSection {...textSection} />
      <Gutter size={textSectionMarginBottom ?? 128} />
    </Body>
    {visualSections.map(
      ({ type, section, marginBottom, constrainWidth }, i) => {
        const Component = componentMap[type];
        return (
          <MarginContainer
            constrainWidth={constrainWidth ?? type !== "caroussel"}
          >
            <Component key={i} {...section} />
            <Gutter size={marginBottom ?? 128} />
          </MarginContainer>
        );
      }
    )}
    <Body
      css={`
        margin-top: 0;
        padding-top: 0;
      `}
    >
      <NextCase {...nextCase} />
      <Gutter size={128} />
      <Footer socials={socials} />
      <BackToTop />
    </Body>
  </>
);

CaseIndex.propTypes = {};

export default CaseIndex;
