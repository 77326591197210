import React, {  useState } from "react";
import styled from "styled-components";

import ContentUnit from "./content-unit";

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledContent = styled(ContentUnit)`
  /* display: block; */
  display: inline-block;
  height: ${({ height, text }) => (text ? "auto" : `${height}px`)};
  width: ${({ width }) => width}px;
  max-width: 100%;
  margin: ${({ spacing }) => spacing / 2}px;
  object-fit: cover;
  -webkit-tap-highlight-color: transparent;
  align-self: ${({ anchor }) => {
    switch (anchor) {
      case "top":
        return "flex-start";
      case "bottom":
        return "flex-end";
      default:
        return anchor;
    }
  }};
`;

const Gallery = ({ slides, spacing = 32, height, aspectRatio }) => {
  const width = height * aspectRatio;
  const [playing, setPlaying] = useState(Array(slides.length).fill(true));
  return (
    <Root height={height}>
      {slides.map((slide, i) => (
        <StyledContent
          text={slide.type === "text"}
          anchor={slide.verticalAlign}
          {...slide}
          height={height}
          width={width}
          spacing={spacing}
          breakpoint={(width + spacing) * slides.length}
          playing={playing[i]}
          onPlayingChange={(newPlaying) =>
            setPlaying([
              ...playing.slice(0, i),
              newPlaying,
              ...playing.slice(i + 1),
            ])
          }
        />
      ))}
    </Root>
  );
};

Gallery.propTypes = {};

export default Gallery;
