import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { ReactComponent as MuteIcon } from "../../svgs/mute.svg";
import { ReactComponent as SoundIcon } from "../../svgs/sound.svg";

const Root = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  &,
  & video {
    height: 100%;
    width: 100%;
  }
  &:focus {
    outline: none;
  }
`;

const PlayIcon = styled.svg`
  position: absolute;
  height: 15%;
  width: 15%;
  min-width: 50px;
  min-height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

const VolumeIcon = styled.svg`
  position: absolute;
  height: 60px;
  width: 60px;
  bottom: 15px;
  right: 15px;
  border-radius: 50%;
  padding: 5px;
  z-index: 10000;
  display: ${({ show }) => (show ? "block" : "none")};
`;

const InlineVideo = ({
  className,
  playing,
  showVolume = false,
  onPlayingChange,
  ...rest
}) => {
  const ref = useRef();
  const [visible, setVisible] = useState(false);
  const [muted, setMuted] = useState(true);
  const [interacting, setInteracting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        setVisible(isIntersecting);
      },
      { rootMargin: "10px 0px" }
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  const state = playing && visible;
  useEffect(() => {
    if (state) {
      // eslint-disable-next-line no-unused-expressions
      ref.current.play()?.catch(console.log);
    } else {
      // eslint-disable-next-line no-unused-expressions
      ref.current.pause()?.catch(console.log);
    }
  }, [state]);

  useEffect(() => {
    ref.current.muted = muted;
  }, [muted]);

  const hideControlsTimeout = useRef();
  return (
    <Root
      onMouseEnter={() => setInteracting(true)}
      onMouseLeave={() => setInteracting(false)}
      onTouchStart={(event) => event.preventDefault() || console.log("canc")}
      tabIndex={0}
      onClick={(event) => {
        clearTimeout(hideControlsTimeout.current);
        if (!interacting && playing) {
          setInteracting(true);
          hideControlsTimeout.current = setTimeout(
            () => setInteracting(false),
            5000
          );
        } else {
          if (!playing) setInteracting(false);
          onPlayingChange(!playing);
        }
      }}
    >
      {!playing && (
        <PlayIcon
          version="1.1"
          viewBox="0 0 58.466 60.007"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <clipPath id="b">
              <path d="m878.56 9060.6c0-46.969 37.101-85.047 82.866-85.047 45.763 0 82.863 38.078 82.863 85.047 0 46.973-37.1 85.051-82.863 85.051-45.765 0-82.866-38.078-82.866-85.051m56.89 37.125 76.75-32.631-76.75-38.283z" />
            </clipPath>
            <clipPath id="a">
              <path d="m871.43 9152.8h179.99v-184.36h-179.99z" />
            </clipPath>
          </defs>
          <g transform="translate(-76.601 -118.16)">
            <g transform="matrix(.35278 0 0 -.35278 -233.34 3344.5)">
              <g clip-path="url(#b)">
                <g clip-path="url(#a)">
                  <path
                    d="m871.43 9152.8h179.99v-184.36h-179.99z"
                    fill="#ffffffb3"
                  />
                </g>
              </g>
            </g>
          </g>
        </PlayIcon>
      )}
      <VolumeIcon
        as={muted ? MuteIcon : SoundIcon}
        onClick={(event) => {
          event.stopPropagation();
          setMuted(!muted);
        }}
        fill="currentColor"
        show={showVolume && interacting}
      />
      <video
        className={className}
        loop
        muted
        x-webkit-airplay="deny"
        disablePictureInPicture=""
        disableRemotePlayback=""
        {...rest}
        ref={ref}
        playsInline
      />
    </Root>
  );
};

export default InlineVideo;
